import "../styles/TaproomDescPage.css";
import "../styles/PageContentFrame.css";

import taproom from "../assets/terasse.jpeg";

import GoBackButton from "../components/GoBackButton";
import sendGA4Event from "../components/GA4Function";

function TaproomDescPage(){
    sendGA4Event("Page description taproom", "Visite de page");
    return(
        <div className="page-content-frame">
            <div className="taproomdesc-frame">
                
                <GoBackButton/>
                <span className="taproomdesc-titre"><h2>Notre Taproom</h2></span>
                <span className="taproomdesc-texte-haut">
                Rejoignez-nous dans notre "Tap Room" où les bières fraîches et originales sont à l'honneur, 
                brassées directement sur place pour une expérience de dégustation chill et authentique.
                </span>
                <div className="taproomdesc-bloc">
                    <img className="taproomdesc-photo" src={taproom} alt="taproom de la brasserie"></img>
                    <div className="taproomdesc-horaire-container">
                        <span className="taproomdesc-horaire-titre"><h3>Les horaires :</h3></span> <br/> 
                        <span className="taproomdesc-horaire-texte">
                       <br/> <br/>
                        Nous mettons à jour régulièrement nos horaires sur <a href="https://www.google.com/search?q=brasserie+sans+façon" target="_blank">notre fiche Google.</a>
                            <br/>
                            <br/>
                            <br/>
                            ZI La Bergerie, 16 Rue Ampère, <br/>
                            49280 La Séguinière, à proximité de Cholet
                        </span>
                    </div>
                </div>
                <span className="taproomdesc-texte-bas">
                Dégustez vos bières à l'intérieur ou sur la terrasse ensoleillée. Ajoutez une touche de fun 
                en jouant au palet ou au molky avec vos amis et vos collègues. Faites de chaque gorgée un moment mémorable !   
                </span>
            </div>
        </div>
    )
}

export default TaproomDescPage;