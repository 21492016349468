import "../styles/RencontrerBloc.css"

import devanture from "../assets/devanture.jpg"
import taproom from "../assets/terasse.jpeg"
import { Link } from "react-router-dom";

// function useNavToTaproomDesc(){
//     const navigate = useNavigate()
//     console.log("test")
//     navigate("/taproom");
// }

// function useNavToBoutiqueDesc(){
//     const navigate = useNavigate()
//     navigate("/boutique");
// }





function showRectoTap() {
    if (document.readyState === 'complete') {
        var recto = document.getElementById("verso-taproom");   //Les 4 fonctions suivantes fonctionnement par paire et permettent d'afficher ou non le slider contenant les horaires et le bouton d'accès à la page de la taproom ou de la boutique
        recto.style.top = '0';
        var titre = document.getElementById("titre-taproom")
        titre.style.opacity = "0"
    }
    
}
function hideRectoTap() {
    var recto = document.getElementById("verso-taproom");
    recto.style.top = '100%';
    var titre = document.getElementById("titre-taproom")
    titre.style.opacity = "100"
}

function showRectoBoutique() {
    var recto = document.getElementById("verso-boutique");
    recto.style.top = '0';
    var titre = document.getElementById("titre-boutique")
    titre.style.opacity = "0"
}
function hideRectoBoutique() {
    var recto = document.getElementById("verso-boutique");
    recto.style.top = '100%';
    var titre = document.getElementById("titre-boutique")
    titre.style.opacity = "100"
}


function WidgetTapRoom() { //cette fonction  créer le widget de la taproom (recto + verso avec bouton)
    return (
        <div className="renc-widget" onMouseOver={showRectoTap} onMouseLeave={hideRectoTap} >
                <div className="renc-widget-recto" >
                    <span id="titre-taproom" className="widget-titre"><h3>Notre Taproom</h3></span>
                    <img className="renc-widget-photo" src={taproom} alt="notre taproom"/>
                </div>
                <div id="verso-taproom" className="renc-widget-verso">
                    <span className="widget-titre">Notre Taproom</span>
                    <span className="widget-horaires">
                        Horaires <br/> <br/>
                        Nous mettons à jour régulièrement nos horaires sur <a href="https://www.google.com/search?q=brasserie+sans+façon" target="_blank">notre fiche Google.</a>
                    </span>
                    <Link to="taproom"><button className="renc-widget-button">Plus d'infos</button></Link>
                </div>
        </div>
    )
}

function WidgetBoutique() { //cette fonction  créer le widget de la boutique (recto + verso avec bouton)
    return (
        <div className="renc-widget" onMouseOver={showRectoBoutique} onMouseLeave={hideRectoBoutique}>
                <div className="renc-widget-recto">
                <span id="titre-boutique" className="widget-titre"><h3>Notre boutique</h3></span>
                    <img className="renc-widget-photo" src={devanture} alt="notre boutique"/>
                </div>
                <div id="verso-boutique" className="renc-widget-verso">
                    <span className="widget-titre">Notre boutique</span>
                    <span className="widget-horaires">
                        Horaires <br/> <br/>
                        Nous mettons à jour régulièrement nos horaires sur <a href="https://www.google.com/search?q=brasserie+sans+façon" target="_blank">notre fiche Google.</a>
                    </span>
                    <Link to="boutique"><button className="renc-widget-button" >Plus d'infos</button></Link>
                </div>
        </div>
    )
}




function RencontrerBloc () {
    return (
        <div className="renc-frame">
            <span className="renc-titre"><h2>Venir nous rencontrer</h2></span>
            <div className="renc-container">
                <WidgetTapRoom/>
                <WidgetBoutique/>
                
            </div>
            <span className="renc-adresse"> <h3>
                ZI La Bergerie, 16 Rue Ampère, 
                49280 La Séguinière, à proximité de Cholet</h3>
            </span>
        </div>
    )
}

export default RencontrerBloc;