import "../styles/EvenementsBloc.css";

import imgTB  from "../assets/TB.png";

function EvenementsBloc() {
    return (
        <div className="event-frame">
            <span className="event-titre"><h2>Sans Façon accueille vos évènements!</h2></span> <br/>
            <span className="event-texte">Vous pouvez nous contacter à l’adresse <a href="mailto:contact@brasseriesansfacon.fr">contact@brasseriesansfacon.fr</a> afin d’organiser votre activité d’entreprise personalisée. </span>
            <span className="event-texte">Les possibilités sont multiples:</span>
            <ul className="event-bullet-list"> 
                <li>Séminaires d'entreprise </li>
                <li>Pots de départ </li>
                <li>Privatisation de la brasserie et after work </li> 
                <li>Coffrets cadeaux pour vos salariées et/ou clients</li>
            </ul>
            <span className="event-texte">et plein d'autres encore !</span>
            {/* <img className="event-photo" src={imgTB} alt="team building Brasserie Sans Façon | image de jcomp sur Freepik"></img> */}
        
        </div>
    )
}

export default EvenementsBloc;