import "../styles/CreationsBloc.css"; 
//import imgFond from "../assets/bieres_herbe.png"
import imgFond from "../assets/cover_bieres.jpg"//"../assets/bieres42.jpg"
import { Link } from "react-router-dom";


function CreationsBloc() {



    return (
        <div className="frame">
            <img className="background-image" src={imgFond} alt="Photographie des bières de la brasserie Sans Façon"/>
            <Link to="/bieres"><button className="btn-creation" ><h2>Nos bières</h2></button></Link> 
        </div>
    )
}

export default CreationsBloc;